import * as React from "react";
import { IconProps } from "..";

const Play = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "play", titleId = "play", ...props }, svgRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          fill="currentColor"
          d="M4 3.532L18.113 12 4 20.468V3.532zM2 0v24l20-12L2 0z"
        />
      </svg>
    );
  }
);
export default Play;
